import { data } from 'autoprefixer';
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './admin.scss'
import Write from '../write/Write'
import Writerankings from '../writerankings/Writerankings';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
export default function Admin() {
    const [mode, setMode] = useState("views");
    const [data, setData] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [validation, setValidation] = useState("none");
    const [views, setViews] = useState([]);
    const [users, setUsers] = useState([]);
    const [remove, setDeleted] = useState("none");
    const [post, setRemove] = useState("none");
    const [rating, setRemoveRating] = useState("none");
    const [count, setCount] = useState(10);
    const [postcount, setPost] = useState(5);
    useEffect(() => {
        const storedMode = localStorage.getItem('mode');
        setMode(storedMode)
        async function getBlogs() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/blog', {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                    },
                    });
                    if (response.status != 200) {
                        setData('error');
                    }
                    if (response.status == 200) {
                        const data = await response.json();
                        setData(data);
                    }
            }catch(e){
                setData('error')
            }
        }
        getBlogs();
        async function getRatings() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/rankings/destinations', {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                    },
                    });
                    if (response.status != 200) {
                        setDestinations('error');
                    }
                    if (response.status == 200) {
                        const data = await response.json();
                        setDestinations(data);
                    }
            }catch(e){
                setData('error')
            }
        }
        getRatings()
        async function getViews() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/viewuses', {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                    },
                    });
                    if (response.status != 200) {
                        setViews('Error');
                    }
                    if (response.status == 200) {
                        const data = await response.json();
                        const data_amount = data.length
                        var visits = document.getElementById("visits");
                        visits.innerHTML = data_amount + " Visits";
                        setViews(data);
                        setDeleted("none")
                    }
            }catch(e){
                setViews('error')
                }
            }
        getViews()
        async function getUsers() {
            try {
                const response = await fetch('https://www.api.kenankorn.com/users/viewsignups', {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                    },
                    });
                    if (response.status != 200) {
                        setViews('Error');
                    }
                    if (response.status == 200) {
                        const data = await response.json();
                        setUsers(data)
                    }
            }catch(e){
                setViews('error')
                }
            }
        getUsers()
        const sortedActivities = data.slice().sort((a, b) => b.date - a.date)
    }, [])
    function addMore() {
        setCount(count+5);
    }
    function loadMore() {
        setPost(postcount+5);
    }
    function checkLocation(e) {
        const coordinates = e.target.id
        const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + coordinates + "&sensor=&key=AIzaSyBXJAjDQI_dE2_-hi5RA05tWT4med-zeXs"
        async function getData() {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                },
            });
            const data = await response.json();
            const city = data.plus_code.compound_code
            var locationDiv = document.getElementById("locationShow");
            locationDiv.innerHTML = '<i className="fa-solid fa-location-crosshairs"></i> ' + city;
        }
        getData()
    }
    function editPost(e) {
        var postID = e.target.id
        window.location.href = '/edit/' + postID
    }
    function deleteRow(e) {
        var date = e.target.id
        let body_data = {
            "user": date,
        }
        async function deleteData() {
            const response = await fetch("https://www.api.kenankorn.com/deleterow", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            setViews(refresheddata)
            setDeleted("newdata")
            toast.success('Removed row!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deleteData()
    }
    function deletePost(e) {
        var postid = e.target.id
        let body_data = {
            "id": postid,
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/deletepost", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            setData(refresheddata)
            setRemove("newdata")
            toast.success('Deleted post!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }
    function deleteRating(e) {
        var postid = e.target.id
        let body_data = {
            "id": postid,
        }
        async function deletePostData() {
            const response = await fetch("https://www.api.kenankorn.com/deleteranking", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            setDestinations(refresheddata)
            setRemoveRating("newdata")
            toast.success('Deleted post!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        deletePostData()
    }
    function approveUser(e) {
        var email = e.target.id
        var password = e.target.getAttribute("user-pass")
        let body_data = {
            "email": email,
            "password": password
        }
        async function approveUserData() {
            const response = await fetch("https://www.api.kenankorn.com/approve", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            setUsers(refresheddata)
            setValidation("validated")
            toast.success('Approved user!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        approveUserData()
    }
    function removeUser(e) {
        var email = e.target.id
        let body_data = {
            "email": email,
        }
        async function removeUserData() {
            const response = await fetch("https://www.api.kenankorn.com/removeuser", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body_data)
            });
            const refresheddata = await response.json();
            setUsers(refresheddata)
            setValidation("validated")
            toast.success('Removed user!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        removeUserData()
    }
    function logOut() {
        toast.success('Logged out!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        document.cookie = "adminCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = "/home"
    }
    function writePost() {
        window.location.href = "/write"
    }
    function toggleViews() {
        localStorage.setItem('mode', 'views');
        setMode("views")
    }
    function toggleBlog() {
        localStorage.setItem('mode', 'posts');
        setMode("posts")
    }
    function toggleWrite() {
        localStorage.setItem('mode', 'write');
        setMode("write")
    }
    function toggleRankings() {
        localStorage.setItem('mode', 'rankings');
        setMode("rankings")
    }
    function toggleUser() {
        localStorage.setItem('mode', 'users');
        setMode("users") 
    }
  return (
    <div>
        <div className="adminNavigation">
            <div className="viewsNav">
                <div className="wrapper" onClick={toggleViews}>
                    <button className="viewsButton"><i className="navIcon fa-solid fa-eye"></i></button>
                    <span className="info">Views</span>
                </div>
            </div>
            <div className="blogNav">
                <div className="wrapper" onClick={toggleBlog}>
                    <button className="blogButton"><i className="navIcon fa-solid fa-blog"></i></button>
                    <span className="info">Blog Posts</span>
                </div>
            </div>
            <div className="postNav">
                <div className="wrapper" onClick={toggleWrite}>
                    <button className="postButton"><i className="navIcon fa-solid fa-pen-clip"></i></button>  
                    <span className="info">Write A Post</span>              </div>
            </div>
            <div className="rankingNav">
                <div className="wrapper" onClick={toggleRankings}>
                    <button className="rankingButton"><i className="navIcon fa-solid fa-star"></i></button>  
                    <span className="info">Destinations</span>              </div>
            </div>
            <div className="userNav">
                <div className="wrapper" onClick={toggleUser}>
                    <button className="postButton"><i className="navIcon fa-solid fa-user"></i></button>  
                    <span className="info">User Approval</span>              </div>
            </div>
        </div>
        <br></br>
        {mode === 'posts' &&
            <>
            <div className="imageHeader">
                <img src="https://picsum.photos/800/400" alt="" className="header" />
                <div className="headingTextTravel"><i className="fa-solid fa-keyboard"></i>Blog Details</div>
            </div>
            <table>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Location</th>
                    <th>Date</th>
                </tr>
                <></>
            {post === 'none' &&
                <>
                    {data.slice(0,postcount).map((blog, i) =>
                            <tr key={i}>
                                <td className='postID'>{blog.id}</td>
                                <td className='locationClass'>{blog.title}</td>
                                <td className='locationClass'>{blog.city}, {blog.country}</td>
                                <td>{blog.date}</td>
                                <i className="deleteIcon fa-solid fa-trash" id={blog.id} onClick={deletePost}></i>
                                <i className="editIcon fa-solid fa-pen-to-square" id={blog.id} onClick={editPost}></i>
                            </tr>
                        )}
                </>
            }
            {post === 'newdata' &&
                <>
                    <ToastContainer />
                    {data.slice(0,postcount).map((blog, i) =>
                            <tr key={i}>
                                <td className='postID'>{blog.id}</td>
                                <td className='locationClass'>{blog.title}</td>
                                <td className='locationClass'>{blog.city}, {blog.country}</td>
                                <td>{blog.date}</td>
                                <i className="deleteIcon fa-solid fa-trash" id={blog.id} onClick={deletePost}></i>
                                <i className="editIcon fa-solid fa-pen-to-square" id={blog.id} onClick={editPost}></i>
                            </tr>
                        )}
                </>
            }
            </table>
            <br></br>
            <button className="addMore" onClick={loadMore}>Load More <span id="count"></span></button>
            </>
        }
            
        {mode === 'views' &&
            <>
                <div className="imageHeader">
                    <img src="https://picsum.photos/800/400" alt="" className="header" />
                    <div className="headingTextTravel"><i className="fa-solid fa-eye"></i>Page Visits</div>
                </div>
                {remove === 'none' &&
                <>
                    <h1 className="visits" id='visits'></h1>
                    <div className="pageVisits" id='visits'></div>
                        <table>
                                <tr>
                                    <th>Location</th>
                                    <th>Time</th>
                                    <th>Date</th>
                                </tr>
                                <>
                                    {views.slice(0,count).map((view, i) =>
                                        <tr key={i}>
                                            <td className={view.location} onClick={checkLocation} className='locationClass' id={view.location}><i className="locationIcon fa-solid fa-location-crosshairs" id={view.location}></i> {view.location}</td>
                                            <td>{view.count}</td>
                                            <td>{view.time}</td>
                                            <i className="deleteIcon fa-solid fa-trash" onClick={deleteRow} id={view.user}></i>
                                        </tr>
                                    )}
                                </>
                            </table>    
                            <br></br>
                            <div className="locationDiv" id="locationShow"></div>    
                            <button className="addMore" onClick={addMore}>Load More</button>  
                        <br></br>
                </>
                }
                {remove === 'newdata' &&
                <>
                    <ToastContainer />
                    <div className="pageVisits" id='visits'></div>
                        <table>
                                <tr>
                                    <th>Location</th>
                                    <th>Time</th>
                                    <th>Date</th>
                                </tr>
                                <>
                                    {views.map((view, i) =>
                                        <tr key={i}>
                                            <td className={view.location} onClick={checkLocation} className='locationClass' id={view.location}><i className="locationIcon fa-solid fa-location-crosshairs" id={view.location}></i> {view.location}</td>
                                            <td>{view.count}</td>
                                            <td>{view.time}</td>
                                            <i className="deleteIcon fa-solid fa-trash" onClick={deleteRow} id={view.user}></i>
                                        </tr>
                                    )}
                                    

                                </>
                            </table>    
                            <br></br>
                            <div className="locationDiv" id="locationShow"></div>
                            <button className="addMore" onClick={addMore}>Load More</button>        
                        <br></br>
                </>
                }
            </>
        }
        {mode === 'write' &&
            <>
                <Write />
            </>
        }
        {mode === 'rankings' &&
            <>
                <Writerankings />
            <>
            <br></br>
            <table>
                <tr>
                    <th>ID</th>
                    <th>Destination</th>
                    <th>Rating</th>
                </tr>
                <></>
                {rating === 'none' &&
                    <>
                        {destinations.slice(0,postcount).map((destination, i) =>
                                <tr key={i}>
                                    <td className='postID'>{destination.id}</td>
                                    <td className='locationClass'>{destination.destination}</td>
                                    <td className='locationClass'>{destination.rating}</td>
                                    <i className="deleteIcon fa-solid fa-trash" id={destination.id} onClick={deleteRating}></i>
                                    <i className="editIcon fa-solid fa-pen-to-square" id={destination.id} onClick={editPost}></i>
                                </tr>
                            )}
                    </>
                }
                {rating === 'newdata' &&
                    <>
                        <ToastContainer />
                        {destinations.slice(0,postcount).map((destination, i) =>
                                <tr key={i}>
                                    <td className='postID'>{destination.id}</td>
                                    <td className='locationClass'>{destination.destination}</td>
                                    <td className='locationClass'>{destination.rating}</td>
                                    <i className="deleteIcon fa-solid fa-trash" id={destination.id} onClick={deletePost}></i>
                                    <i className="editIcon fa-solid fa-pen-to-square" id={destination.id} onClick={editPost}></i>
                                </tr>
                            )}
                    </>
                }
                </table>
                <br></br>
                <button className="addMore" onClick={loadMore}>Load More <span id="count"></span></button>
                </>
            }
            </>
        }
        {mode === 'users' &&
            <>
            <div className="imageHeader">
                <img src="https://picsum.photos/800/400" alt="" className="header" />
                <div className="headingTextTravel"><i className="fa-solid fa-keyboard"></i>Blog Details</div>
            </div>
            <table>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Password</th>
                </tr>
                <></>
            {validation === 'none' &&
                <>
                    {users.slice(0,postcount).map((user, i) =>
                            <tr key={i}>
                                <td className='postID'>{user.name}</td>
                                <td className='locationClass'>{user.email}</td>
                                <td className='locationClass'>{user.password}</td>
                                <i className="checkIcon fa-solid fa-circle-check" id={user.name} onClick={approveUser}></i>
                                <i className="deleteIcon fa-solid fa-circle-xmark" id={user.name} onClick={removeUser}></i>
                            </tr>
                        )}
                </>
            }
            {validation === 'validated' &&
                <>
                    <ToastContainer />
                    {users.slice(0,postcount).map((user, i) =>
                            <tr key={i}>
                                <td className='postID'>{user.name}</td>
                                <td className='locationClass'>{user.email}</td>
                                <td className='locationClass'>{user.password}</td>
                                <i className="checkIcon fa-solid fa-circle-check" id={user.email} user-pass={user.password} onClick={approveUser}></i>
                                <i className="deleteIcon fa-solid fa-circle-xmark" id={user.email} onClick={removeUser}></i>
                            </tr>
                        )}
                </>
            }
            </table>
            <br></br>
            <button className="addMore" onClick={loadMore}>Load More</button>
            </>
        }
        <br></br>
        <div className="logOut">
            <ToastContainer />
        </div>
    </div>
  )
}

