import React from 'react'
import {useState, useEffect} from 'react';
import './write.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Return from '../navbar/Return';
export default function Write() {
    const [success, setSuccess] = useState("none");
    useEffect(() => {
        var nowDate = new Date(); 
        var date = (nowDate.getMonth()+1)+'/'+nowDate.getDate() + '/' + nowDate.getFullYear();
        var dateHTML = document.getElementById("time");
        dateHTML.value = date;
    }, [])
    function showSuccess() {
        setSuccess("success")
        toast.success('Successfully posted!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
  return (
    <div className='writeBlog'>
        <div className="collectionsHeader">
                <img src="https://kenanphotography.s3.us-west-1.amazonaws.com/_DSC7007.jpg" className='collectionsImage' id='headingImage' alt="header"></img>
                <div className="centered">
                    <h1 className="headerCollections">Write a Post</h1>
                    <div className="subheaderCollections">
                        Write a post here!
                    </div>
                </div>
            </div>
            <br></br>
        <iframe name="success" id="success"></iframe>
        <form method="POST" action="https://www.api.kenankorn.com/postblog" className="writeForm" target='success' onSubmit={showSuccess}>
            <input className="writeInput" name='link' placeholder="Enter link"></input>
            <br></br><br></br>
            <input className="writeInput" name='title' placeholder="Enter title"></input>
            <br></br><br></br>
            <input className="writeInput" name='city' placeholder="Enter city"></input>
            <br></br><br></br>
            <input className="writeInput" name='country' placeholder="Enter country"></input>
            <br></br><br></br>
            <textarea className="enterLink" name='content' placeholder="Write post"></textarea>
            <br></br>
            <input type="submit" value="Submit" className='submitWrite'></input>
            <input id="time" name="date" type="hidden"></input>
        </form>
        {success === 'success' &&
            <>
            <ToastContainer />
            <div className="successDiv">
                <i className="checkMark fa-solid fa-circle-check"></i>Your blog post has been uploaded!
            </div>
            </>
        }
    </div>
  )
}

