import React, { useState, useEffect } from 'react';
import './loader.scss';
import FadeInOut from './FadeInOut';
import chaouen from '../../assets/chaouenHeader.jpg';
import sunset from '../../assets/blogHeader.jpg';
import halong from '../../assets/halong.jpg';
import marietas from '../../assets/marietas.jpg';
import waterfall from '../../assets/waterfall.jpg';
import { CircularProgress } from '@mui/material';  // Add this line
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

const images = [chaouen, sunset, halong, marietas, waterfall];
const texts = ['Loading', 'Getting my images', 'Traveling to find data', 'Wait a few seconds please', 'Waiting at baggage claim'];

const Loader = ({ loaded, data }) => {
  const [currentImage, setCurrentImage] = useState(null);
  const [loadingText, setLoadingText] = useState('');
  const [fade, setFade] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const randomInteger = Math.floor(Math.random() * images.length);
  const [randomIndex, setRandomIndex] = useState(randomInteger);

  useEffect(() => {
    setCurrentImage(images[randomIndex]);
    setLoadingText(texts[currentIndex]);
    setFade(true);
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
      setRandomIndex((randomIndex + 1) % images.length);
      setFade(false);
    }, 6000);
    return () => clearInterval(interval);
  }, [currentIndex, fade]);

  const loadingPercentage = loaded && data ? ((loaded / data) * 100).toFixed(0) : 0;

  return (
    <div className="loader-wrapper">
      <div className={`header ${fade ? 'fade' : 'fade-out'}`}>
        <img src={currentImage} alt="" className="loading-image" />
        <div className="header-contents">
          <FadeInOut show={true} duration={500}>
            <div className="loading-indicator">
            <CircularProgress variant="determinate" value={loadingPercentage} size={80} thickness={5} style={
                {
                    //linear gradient background 
                    color: 'linear-gradient(90deg, #3D70F4 0%, #45C4B0 100%)',
                }
            }
            />
              </div>
              <h1 className="loading-text">
                {loadingPercentage === 0 && 'Loading...'}
                {loadingPercentage === 100 && 'Complete'}
                {loadingPercentage != 100 && loadingPercentage != 0 &&
                  <>
                    {loadingPercentage}%
                    </>
                }
              </h1>
          </FadeInOut>
        </div>
      </div>
    </div>
  );
};

export default Loader;