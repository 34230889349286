import React, { useState, useEffect } from 'react';
import './password.scss';
import Image from "../../assets/headers/9.jpg"
import CircularProgress from '@mui/material/CircularProgress';
const Password = () => {
  const [clientPassword, setClientPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMess, setErrorMess] = useState('');
  const [username, setUsername] = useState('');
  const [usernameDisplay, setUsernameDisplay] = useState(false);

  document.title = "Client Login"

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const usernameParam = urlParams.get('username');
    if (usernameParam) {
      setUsername(usernameParam);
    }else{
      setUsernameDisplay(true);
    }
  }, []);


  const loginClient = async () => {
    setLoading(true);
    try {
        const response = await fetch('https://www.api.kenankorn.com/client/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username: username, password: clientPassword }),
        });

        if (response.status === 200) {
            const data = await response.json();
            const token = data.token;
            localStorage.setItem('token', token); // save the token in local storage
            window.location.href = "/client/dashboard";
        } else {
            setError(true);
            const data = await response.json();
            setErrorMess(data.message)
        }
        setLoading(false);
    } catch (error) {
        setError(true);
        setLoading(false);
        setErrorMess("An error occured. Please try again.")
        console.log(error)
    }
};


  return (
    <div className="password-wrapper">

        {loading === true && 
            <div className="loading-client">
                <CircularProgress />
            </div>
        }
      <div className="login-form">
        <div className="login-smaller">
            {usernameDisplay &&
              <h2 className="login-title">Client Login Portal</h2>
            }
            {!usernameDisplay &&
              <h2 className="login-title">Welcome, <span className='user-text'>{username.charAt(0).toUpperCase() + username.slice(1)}</span></h2>
            }
            <p className="login-subtitle">Please enter your password to access your client portal.</p>
            <div className="form-group">
              {usernameDisplay &&
                <>
                  <label htmlFor="password">Client Username</label>
                  <input
                      type="text"
                      id="username"
                      className='password-input'
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                  />
                </>
                }
                <label htmlFor="password">Client Password</label>
                <input
                    type="password"
                    id="password"
                    className='password-input'
                    value={clientPassword}
                    onChange={(e) => setClientPassword(e.target.value)}
                />
                
                <button  className="password-button" onClick={loginClient}>Login</button>
            </div>
            {error && <p className="error">{errorMess}</p>}
        </div>
        
      </div>
      <div className="login-image">
        <img src={Image} alt="" className="login-img-image" />
        <div className="top-right-text">
            Kenan Korn
        </div>
      </div>
      
    </div>
  );
};

export default Password;
