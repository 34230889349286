import React from 'react'
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login.scss'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
export default function Login() {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [login, setLogin] = useState("False");
    const [newuser, setNew] = useState("false");
    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let res = await fetch("https://www.api.kenankorn.com/login", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                username: username,
                password: password,
                }),
        })
        let resJson = await res.json();
        if (res.status === 200) {
            function setCookie(cname, exdays) {
                const d = new Date();
                d.setTime(d.getTime() + (exdays*24*60*60*1000));
                var expires = new Date();
                expires = new Date(expires.getTime() +1000*60*60*24*365);
                let cvalue = (Math.random() + 1).toString(36).substring(7);
                document.cookie = 'adminCookie='+ cvalue + '; expires='+expires.toGMTString()+';'; 
                }
            setCookie()
            window.location.href = "/admin"
        } else {
            toast.error('Invalid credentials!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLogin("Invalid")
        }
        } catch (err) {
            toast.error('Login error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLogin("Error")
        }
    };
    let handleNew = async (e) => {
        e.preventDefault();
        try {
            let res = await fetch("https://www.api.kenankorn.com/signups", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    username: username,
                    password: password,
                }),
        })
        let resJson = await res.json();
        if (res.status === 200) {
            toast.success('Admin request sent!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLogin("Invalid")
        } else {
            toast.error('Invalid credentials!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLogin("Invalid")
        }
        } catch (err) {
            toast.error('Login error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLogin("Error")
        }
    };
    function toggleSignup() {
        setNew("true")
    }
    function toggleLogin() {
        setNew("false")
    }
  return (
    <div className='login'>
        {newuser === 'false' &&
        <div className="centerLogin">
                <div className="welcomeHeader">
                    <h1 className="welcome">Welcome, Admin!</h1>
                    <div className="welcomeSub">This login panel is only for admins.</div>
                </div>
                <iframe name="success" id="success"></iframe>
                <form onSubmit={handleSubmit} className="login-form" target='success'>
                    <div className="formWrapper">
                    <label htmlFor="password">Email</label>
                    <br></br>
                    <input className="email" name='email' className="password-input" placeholder="Enter your email" onChange={(e) => setUsername(e.target.value)}></input>
                    <br></br><br></br>
                    <label htmlFor="password">Password</label>
                    <br></br>
                    <input type="password" className="password-input" name='password' placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)}></input>
                    <br></br>
                    </div>
                    <br></br>
                    <input type="submit" className='password-button' value="Login"></input>
                </form>
                <div className="signUpSection">
                    <div className="signUpText">
                        <div className="signUpDetail"> Want to be part of the team?</div>
                       <button className="signUp" onClick={toggleSignup}>Sign up</button>
                    </div>
                </div>
        </div>
        }
        <>
            {login === 'Invalid' &&
                <>
                    <ToastContainer />
                </>
            }
        </>
        <>
            {login === 'Error' &&
                <>
                    <ToastContainer />
                </>
            }
        </>
        {newuser === 'true' &&
            <>
                     <div className="centerLogin">
                        <div className="welcomeHeader">
                            <h1 className="welcome">Admin Sign Up</h1>
                            <div className="welcomeSub">You will not receive admin status unless approved by me. <br></br>If approved, you will be contacted.</div>
                        </div>
                        <iframe name="success" id="success"></iframe>
                        <form onSubmit={handleNew} className="loginForm" target='success'>
                            <span className="detail">Name</span>
                            <br></br>
                            <input className="maininput" name='name' placeholder="Enter your full name" onChange={(e) => setName(e.target.value)}></input>
                            <br></br><br></br>
                            <span className="detail">Email</span>
                            <br></br>
                            <input className="maininput" name='email' placeholder="Enter your email" onChange={(e) => setUsername(e.target.value)}></input>
                            <br></br><br></br>
                            <span className="detail">Password</span>
                            <br></br>
                            <input type="password" className="maininput" name='password' placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)}></input>
                            <br></br>
                            <input type="submit" className='submitButton' value="Sign Up"></input>
                        </form>
                <div className="signUpSection">
                    <div className="signUpText">
                       <button className="signUp" onClick={toggleLogin}>Login</button>
                    </div>
                </div>
        </div>
                <>
                    {login === 'Invalid' &&
                        <>
                            <ToastContainer />
                        </>
                    }
                </>
                <>
                    {login === 'Error' &&
                        <>
                            <ToastContainer />
                        </>
                    }
                </> 
                </> 
        }
    </div>
    
  )
}

