import React from 'react'
import './error.scss'
export default function Error() {
    function home() {
        window.location.href = "/home"
    }
  return (
    <div className='errorPage'>
        <div className="four">404</div>
       <div className="mainError">Oops! Invalid URL.</div> 
       <button className="home" onClick={home}>Back Home</button>
    </div>
  )
}

