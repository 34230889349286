import React from 'react'
import { CircularProgress } from '@mui/material'
import './loader.scss'

const LoaderSpinner = () => {
  return (
    <div className='loader-spinner'>
        <CircularProgress />
    </div>
  )
}

export default LoaderSpinner