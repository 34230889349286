import { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gallery from './components/gallery/Gallery';
import Password from './components/client/Password';
import Admin from './components/admin/Admin';
import Login from './components/login/Login';
import Error from './components/error/Error';
import Blocked from './components/error/Blocked';
import Loader from './components/loader/Loader';
import Client from './components/client/Client';
import Cookies from 'universal-cookie';
import './App.scss';

const API_URL = "https://www.api.kenankorn.com";

class SessionManager {
  static async initializeSession() {
    try {
      // Generate userId if not exists
      let userId = localStorage.getItem('userId');
      if (!userId || userId === 'anonymous') {
        userId = `user_${Math.random().toString(36).substr(2, 9)}_${Date.now()}`;
        localStorage.setItem('userId', userId);
      }

      // Check existing token
      const token = localStorage.getItem('accessToken');
      if (token) {
        const response = await fetch(`${API_URL}/session`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-User-ID': userId
          }
        });
        
        if (response.ok) {
          console.log('Existing token validated');
          return { accessToken: token, userId };
        }
        console.log('Token invalid, removing');
        localStorage.removeItem('accessToken');
      }

      // Create new session
      console.log('Creating new session');
      const response = await fetch(`${API_URL}/session/initialize`, {
        method: 'POST',
        credentials: 'include',
        headers: { 
          'Content-Type': 'application/json',
          'X-User-ID': userId
        },
        body: JSON.stringify({ userId })
      });

      if (!response.ok) {
        throw new Error(`Failed to initialize session: ${response.status}`);
      }

      const data = await response.json();
      console.log('New session created');
      localStorage.setItem('accessToken', data.accessToken);
      return { ...data, userId };
    } catch (error) {
      console.error('Session initialization error:', error);
      throw error;
    }
  }

  static async keepSessionAlive() {
    const token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    
    if (!token || !userId) {
      console.log('No token or userId found for keepalive');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/session/keepalive`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-User-ID': userId
        }
      });

      if (!response.ok && response.status === 401) {
        console.log('Session expired, refreshing...');
        await this.refreshSession();
      }
    } catch (error) {
      console.error('Session keepalive error:', error);
    }
  }

  static async refreshSession() {
    const userId = localStorage.getItem('userId');
    try {
      const response = await fetch(`${API_URL}/refresh`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-User-ID': userId
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to refresh session: ${response.status}`);
      }

      const data = await response.json();
      localStorage.setItem('accessToken', data.accessToken);
      return data;
    } catch (error) {
      console.error('Session refresh error:', error);
      throw error;
    }
  }
}


const generateOrGetUserId = () => {
  let userId = localStorage.getItem('userId');
  if (!userId || userId === 'anonymous') {
    userId = `user_${Math.random().toString(36).substr(2, 9)}_${Date.now()}`;
    localStorage.setItem('userId', userId);
  }
  return userId;
};

async function trackInteraction(type, details = {}) {
  try {
    const token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');

    if (!token || !userId) {
      console.log('Missing token or userId for tracking, reinitializing session...');
      try {
        const sessionData = await SessionManager.initializeSession();
        if (!sessionData.accessToken) {
          throw new Error('Failed to initialize session');
        }
      } catch (error) {
        console.error('Failed to reinitialize session:', error);
        return;
      }
    }

    console.log(`Tracking interaction: ${type}`);
    const response = await fetch(`${API_URL}/track/interaction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        'X-User-ID': localStorage.getItem('userId')
      },
      body: JSON.stringify({
        type,
        details: {
          ...details,
          userId: localStorage.getItem('userId'),
          path: window.location.pathname,
          timestamp: new Date().toISOString(),
          viewport: {
            width: window.innerWidth,
            height: window.innerHeight
          },
          deviceInfo: {
            type: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(navigator.userAgent) 
              ? 'mobile' 
              : 'desktop',
            platform: navigator.platform,
            vendor: navigator.vendor
          }
        }
      })
    });

    if (response.status === 401) {
      console.log('Token expired during tracking, refreshing...');
      await SessionManager.refreshSession();
      // Retry the tracking after refresh
      return trackInteraction(type, details);
    }

    if (!response.ok) {
      throw new Error(`Failed to track interaction: ${response.status}`);
    }
    
    console.log(`Successfully tracked interaction: ${type}`);
  } catch (error) {
    console.error('Error tracking interaction:', error);
  }
}


function App() {
  const cookies = new Cookies();
  const checkCookie = cookies.get('adminCookie');
  const [isInitialized, setIsInitialized] = useState(false);
  const sessionInitialized = useRef(false);
  const initializationAttempted = useRef(false);

  // Initialize session
  useEffect(() => {
    const initSession = async () => {
      // Skip if already attempted or if admin cookie exists
      if (initializationAttempted.current || checkCookie) {
        return;
      }

      initializationAttempted.current = true;

      try {
        console.log('Starting session initialization...');
        const sessionData = await SessionManager.initializeSession();
        
        if (sessionData.accessToken) {
          sessionInitialized.current = true;
          setIsInitialized(true);
          console.log('Session successfully initialized');
          
          // Track initial session
          await trackInteraction('SESSION_START', {
            source: 'app_init',
            userAgent: navigator.userAgent,
            referrer: document.referrer || 'direct'
          });
        }
      } catch (error) {
        console.error('Failed to initialize session:', error);
      }
    };

    if (!sessionInitialized.current && !checkCookie) {
      initSession();
    }
  }, [checkCookie]);


  // Keep session alive
  useEffect(() => {
    if (!isInitialized) return;

    const keepAliveInterval = setInterval(async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) return;

      try {
        const response = await fetch(`${API_URL}/session/keepalive`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          console.log('Session keepalive failed, clearing token');
          localStorage.removeItem('accessToken');
          setIsInitialized(false);
        }
      } catch (error) {
        console.error('Keepalive error:', error);
      }
    }, 5 * 60 * 1000); // Every 5 minutes

    return () => clearInterval(keepAliveInterval);
  }, [isInitialized]);


  // Track page views
  useEffect(() => {
    if (!isInitialized) {
      console.log('Skipping page view tracking - session not initialized');
      return;
    }

    const trackPageView = async () => {
      console.log('Tracking page view:', window.location.pathname);
      await trackInteraction('PAGE_VIEW', {
        referrer: document.referrer || 'direct',
        timeSpent: Math.floor((Date.now() - window.performance.timing.navigationStart) / 1000),
        url: window.location.pathname
      });
    };

    trackPageView();
  }, [window.location.pathname, isInitialized]);

  // Prevent context menu on images
  useEffect(() => {
    const preventContextMenu = (e) => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        return false;
      }
    };

    document.addEventListener('contextmenu', preventContextMenu);
    return () => document.removeEventListener('contextmenu', preventContextMenu);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Gallery />} />
          <Route exact path="/home" element={<Gallery />} />
          <Route path="/login" element={checkCookie ? <Admin /> : <Login />} />
          <Route path="*" element={<Error />} />
          <Route path="/loading" element={<Loader />} />
          <Route path="/blocked" element={<Blocked />} />
          <Route path="/client/dashboard" element={<Client />} />
          <Route exact path="/client/login" element={<Password />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;