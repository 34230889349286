import React from 'react'
import {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Write() {
    const [success, setSuccess] = useState("none");
    useEffect(() => {
        var nowDate = new Date(); 
        var date = (nowDate.getMonth()+1)+'/'+nowDate.getDate() + '/' + nowDate.getFullYear();
    }, [])
    function showSuccess() {
        setSuccess("success")
        toast.success('Successfully posted!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
  return (
    <div className='writeBlog'>
        <iframe name="success" id="success"></iframe>
        <form method="POST" action="https://www.api.kenankorn.com/postdestination" className="writeForm" target='success' onSubmit={showSuccess}>
            <input className="enterIMage" name='image' placeholder="Enter image"></input>
            <br></br><br></br>
            <input className="enterLink" name='destination' placeholder="Enter destination"></input>
            <br></br><br></br>
            <input className="enterTitle" name='description' placeholder="Enter description"></input>
            <br></br><br></br>
            <input className="enterLink" name='rating' placeholder="Enter ranking (/5)"></input>
            <br></br><br></br>
            <input type="submit" value="Submit" className='submitWrite'></input>
        </form>
        {success === 'success' &&
            <>
            <ToastContainer />
            <div className="successDiv">
                <i className="checkMark fa-solid fa-circle-check"></i>Your blog post has been uploaded!
            </div>
            </>
        }
    </div>
  )
}

