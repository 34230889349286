import React, { useRef, useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './header.scss';
import ColorThief from 'colorthief'; // import the ColorThief library
import image1 from '../../../assets/headers/1.jpg';
import image2 from '../../../assets/headers/2.jpg';
import image3 from '../../../assets/headers/3.jpg';
import image4 from '../../../assets/headers/4.jpg';
import image5 from '../../../assets/headers/5.jpg';
import image6 from '../../../assets/headers/6.jpg';
import image7 from '../../../assets/headers/7.jpg';
import image8 from '../../../assets/headers/8.jpg';
import image9 from '../../../assets/headers/9.jpg';
import image10 from '../../../assets/headers/10.jpg';
import image11 from '../../../assets/headers/11.jpg';
import image12 from '../../../assets/headers/12.jpg';
import image13 from '../../../assets/headers/13.jpg';
import image14 from '../../../assets/headers/14.jpg';
import image15 from '../../../assets/headers/15.jpg';
import image16 from '../../../assets/headers/16.jpg';
import image17 from '../../../assets/headers/17.jpg';
import image18 from '../../../assets/headers/18.jpg';
import image19 from '../../../assets/headers/19.jpg';
import image20 from '../../../assets/headers/20.jpg';
import image21 from '../../../assets/headers/21.jpg';
import image22 from '../../../assets/headers/22.jpg';
import image23 from '../../../assets/headers/23.jpg';
import image24 from '../../../assets/headers/24.jpg';

const Header = (props) => {
  const mainWrapperRef = useRef(null);
  const [gradient, setGradient] = useState(false);
  const [mainHeader, setMainHeader] = useState('');
  const [gradientColors, setGradientColors] = useState(['#000000', '#FFFFFF']); // set initial gradient colors
  const [mainColor, setMainColor] = useState(); // set initial gradient colors
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSmallScreen, setIsSmallScreen] = useState(screenWidth < 785);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
      setIsSmallScreen(screenWidth < 500);
      setGradient(false);
      props.setHeaderDisplay(true);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenWidth]);

  const scrollToMainWrapper = () => {
    mainWrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }).join('')

  function hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var hue2rgb = function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  function rgbToHsl(r, g, b) {
    r /= 255; g /= 255; b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    return [h, s, l];
  };


  function adjustBrightness(color, amount) {
    const [r, g, b] = color;
    const hsl = rgbToHsl(r, g, b);
    hsl[2] = Math.min(1, Math.max(0, hsl[2] + amount));
    return hslToRgb(...hsl);
  }


  useEffect(() => {
    const imageArray = [    image1,    image2,    image3,    image4,    image5,    image6,    image7,    image8,    image9,    image10,    image11,    image12,    image13,    image14,    image15,   image16,    image17,    image18,    image19,    image20,    image21,    image22,   image23,    image24,  ];
    const randomIndex = Math.floor(Math.random() * imageArray.length);
    const randomImage = imageArray[randomIndex];
    setMainHeader(randomImage);
  
    // create a new ColorThief instance and get the dominant colors from the random image
    const colorThief = new ColorThief();
    const img = new Image();
    img.src = randomImage;
  
    // use setTimeout() to defer the execution of the load event handler until after the browser has finished rendering the image
    const handleLoad = () => {
      setTimeout(() => {
        const colors = colorThief.getPalette(img, 5);
        const mainColor = colorThief.getColor(img);
        const hexMain = rgbToHex(...mainColor);
        setMainColor(hexMain);
        const adjustedColors = colors.map((color) => adjustBrightness(color, 0.2));
        const hexColors = adjustedColors.map((color) => rgbToHex(...color));
        setGradientColors(hexColors);
        setGradient(true);
        props.setHeaderDisplay(true);
      }, 0);
    };
  
    img.addEventListener("load", handleLoad);
  
    return () => {
      img.removeEventListener("load", handleLoad);
    };
  }, []);
  



  const gradientStyle2 = {
    backgroundImage: `linear-gradient(to right, ${gradientColors[0]}, ${gradientColors[1]}, ${gradientColors[2]})`,
    backgroundSize: '100% 100%',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent',
    width: '100%',
    padding: '0px 20px 0px 30px',
    WebkitTextFillColor: 'transparent',
    display: 'block',
    WebkitBoxDecorationBreak: 'clone',
    WebkitTextFillColor: 'transparent',
  };







  return (
    <div className='header-wrapper'>
      <div className='header'>
        <LazyLoadImage src={mainHeader} alt='' className='header-image' effect='blur' style={{ height: '100vh' }} />
        <div className='header-contents'>
          {gradient == false &&
            <h1 className='name-false'>Kenan Korn</h1>
          }
          {gradient == true &&
            <h1 className='name' style={gradientStyle2}>Kenan Korn</h1>
          }
          {gradient == false &&
            <p className='information'>
              Welcome to my photography site.
              <br></br>
              Scroll to see my pictures
            </p>
          }
          {gradient == true &&
            <p className='information' style={gradientStyle2}>
              Welcome to my photography site.
              <br></br>
              Scroll to see my pictures
            </p>
          }
          
          <div className='scroll-button-wrapper'>
            <button className={`btn scroll-button draw-border ${gradient ? 'move-left' : ''}`} onClick={scrollToMainWrapper}>
              View Collections <i className='down-arrow fa-solid fa-down-long'></i>
            </button>
          </div>
        </div>
      </div>
      <div ref={mainWrapperRef} className='mainWrapper'></div>
    </div>
  );
};

export default Header;
