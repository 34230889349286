import React from 'react'
import './error.scss'
export default function Error() {
    function home() {
        window.location.href = "/home"
    }
  return (
    <div className='errorPage'>
        <div className="four"><i className="fa-solid fa-lock"></i></div>
       <div className="mainError">You have been blocked.</div> 
       <div className="subText">Your device has triggered a flag in the system. Please access from another device.</div>
    </div>
  )
}

