import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import './contact.scss';

function Contact({ open, handleClose }) {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        // Clear any previous error message when user starts typing
        if (submissionStatus) setSubmissionStatus('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmissionStatus('');

        try {
            const response = await fetch('https://www.api.kenankorn.com/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok && result.success) {
                setSubmissionStatus('Thank you! Your message has been sent successfully.');
                setFormData({ name: '', email: '', message: '' }); // Clear form
                setTimeout(() => {
                    handleClose(); // Close modal after delay
                }, 2000);
            } else {
                setSubmissionStatus(`Error: ${result.error || 'Failed to send message. Please try again.'}`);
            }
        } catch (error) {
            console.error('Contact form submission error:', error);
            setSubmissionStatus('Network error. Please check your connection and try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="contact-modal"
            aria-describedby="contact-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 400,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    bgcolor: 'rgba(255, 255, 255, 0.9)',
                    boxShadow: 24,
                    borderRadius: 5,
                    p: 4,
                }}
            >
                <h2 className="header-contact">Contact Me</h2>
                <p className="subtitle-contact">Fill out the form below to get in touch with me.</p>
                <form className="form-contents" onSubmit={handleSubmit}>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="John Doe"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        disabled={isSubmitting}
                    />
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="johndoe@gmail.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        disabled={isSubmitting}
                    />
                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        placeholder="Enter your message here"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        disabled={isSubmitting}
                    />
                    <button 
                        type="submit" 
                        className="submit-button-contact"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Sending...' : 'Submit'}
                    </button>
                </form>
                {submissionStatus && (
                    <p className={`submission-status ${submissionStatus.includes('Error') ? 'error' : 'success'}`}>
                        {submissionStatus}
                    </p>
                )}
            </Box>
        </Modal>
    );
}

export default Contact;