// tracking-utils.js

const API_URL = "https://www.api.kenankorn.com"

// Constants for event types
export const EventTypes = {
    PAGE_VIEW: 'PAGE_VIEW',
    SCROLL: 'SCROLL',
    RESIZE: 'RESIZE',
    VIEW_IMAGE: 'VIEW_IMAGE',
    VIEW_COLLECTION: 'VIEW_COLLECTION',
    PAGE_CHANGE: 'PAGE_CHANGE',
    SESSION_START: 'SESSION_START',
    PAGE_EXIT: 'PAGE_EXIT'
  };
  
  // Helper to format interaction details
  const formatInteractionDetails = (type, details = {}) => {
    const baseDetails = {
      timestamp: new Date().toISOString(),
      path: window.location.pathname,
      url: window.location.href,
      userAgent: navigator.userAgent,
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      deviceInfo: {
        type: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(navigator.userAgent) ? 'mobile' : 'desktop',
        platform: navigator.platform,
        vendor: navigator.vendor
      },
      performance: {
        timeOnPage: Math.floor((Date.now() - performance.timing.navigationStart) / 1000),
        loadTime: window.performance.timing.loadEventEnd - window.performance.timing.navigationStart,
        renderTime: window.performance.timing.domComplete - window.performance.timing.domLoading
      }
    };
  
    // Add specific details based on event type
    switch (type) {
      case EventTypes.SCROLL:
        return {
          ...baseDetails,
          scrollDepth: `${((window.pageYOffset + window.innerHeight) / document.documentElement.scrollHeight * 100).toFixed(2)}%`,
          scrollPosition: window.pageYOffset,
          pageHeight: document.documentElement.scrollHeight,
          ...details
        };
  
      case EventTypes.VIEW_IMAGE:
        return {
          ...baseDetails,
          imageId: details.imageId || 'unknown',
          imageName: details.imageName || 'unknown',
          imageLocation: details.imageLocation || { city: 'unknown', country: 'unknown' },
          collectionName: details.collectionName || 'unknown',
          viewDuration: details.duration || 0,
          ...details
        };
  
      case EventTypes.VIEW_COLLECTION:
        return {
          ...baseDetails,
          collectionName: details.collectionName || 'unknown',
          imagesCount: details.imagesCount || 0,
          filterType: details.filterType || 'default',
          ...details
        };
  
      case EventTypes.PAGE_CHANGE:
        return {
          ...baseDetails,
          fromPage: details.fromPage || 1,
          toPage: details.toPage || 1,
          direction: details.direction || 'next',
          totalItems: details.totalImages || 0,
          ...details
        };
  
      default:
        return { ...baseDetails, ...details };
    }
  };
  
  // Main tracking function
  export const trackInteraction = async (type, rawDetails = {}) => {
    try {
      const userId = localStorage.getItem('userId');
      const accessToken = localStorage.getItem('accessToken');
  
      if (!userId || !accessToken) {
        console.warn('Missing userId or accessToken for tracking');
        return;
      }
  
      // Format the interaction details
      const details = formatInteractionDetails(type, rawDetails);
  
      console.log(`Tracking interaction: ${type}`, details);
  
      const response = await fetch(`${API_URL}/track/interaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-User-ID': userId
        },
        body: JSON.stringify({
          type,
          details,
          userId,
          sessionId: localStorage.getItem('sessionId')
        })
      });
  
      if (!response.ok) {
        throw new Error(`Failed to track interaction: ${response.status}`);
      }
  
      console.log(`Successfully tracked interaction: ${type}`);
    } catch (error) {
      console.error('Error tracking interaction:', error);
    }
  };